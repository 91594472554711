/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BalanceDto } from '../models';
import { CreateCodesResponseDto } from '../models';
import { ForbiddenException } from '../models';
import { GetOperationsResponseDto } from '../models';
import { GetOrganizationsResponseDto } from '../models';
import { OrganizationCardResponseDto } from '../models';
import { PartnerRedeemCodePayloadDto } from '../models';
import { ReadCodesResponseDto } from '../models';
import { ReadExchangeRateResponseDto } from '../models';
import { RedeemCodeByPartnerResponseDto } from '../models';
import { PartnerPosition } from '@/entities/partner-position-entity';

/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Сгенерировать промо-коды
     * @param {number} offerId
     * @param {number} pool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGenerateCoupons: async (offerId: number, pool: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling partnerControllerGenerateCoupons.');
      }
      // verify required parameter 'pool' is not null or undefined
      if (pool === null || pool === undefined) {
        throw new RequiredError('pool', 'Required parameter pool was null or undefined when calling partnerControllerGenerateCoupons.');
      }
      const localVarPath = `/api/2.0/task/{offer_id}/generate-codes/{pool}`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)))
        .replace(`{${'pool'}}`, encodeURIComponent(String(pool)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updatePositionPartner: async (partnerPositions: PartnerPosition[]): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/partner/positions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions };
      localVarRequestOptions.data = partnerPositions

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Получить обменный курс валют
     * @param {string} from Наименование исходной валюты
     * @param {string} to Наименование целевой валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetExchangeRate: async (from: string, to: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError('from', 'Required parameter from was null or undefined when calling partnerControllerGetExchangeRate.');
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError('to', 'Required parameter to was null or undefined when calling partnerControllerGetExchangeRate.');
      }
      const localVarPath = `/api/2.0/partner/exchange-rate/{from}/{to}`
        .replace(`{${'from'}}`, encodeURIComponent(String(from)))
        .replace(`{${'to'}}`, encodeURIComponent(String(to)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список операций (от имени организации). Авторизация по токену партнёра
     * @param {number} [rsv_user_id] Идентификатор пользователя в РСВ
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetOperations: async (rsv_user_id?: number, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/partner/operations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (rsv_user_id !== undefined) {
        localVarQueryParameter['rsv_user_id'] = rsv_user_id;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (accountIds !== undefined) {
        localVarQueryParameter['account_ids'] = accountIds;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка партнеров. Необязательная авторизация
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {number} [id] Уникальный идентификатор card&#x27;a
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetPartners: async (skip?: number, take?: number, id?: number, options: any = {}): Promise<RequestArgs> => {
      // const localVarPath = `/api/2.0/our-partners`;
      const localVarPath = `/api/2.0/partners`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список партнеров
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetPartnersList: async (id?: number, skip?: number, take?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/partners`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Возвращает статус загрузки промо-кодов
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetUploadCouponsTaskStatus: async (offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling partnerControllerGetUploadCouponsTaskStatus.');
      }
      const localVarPath = `/api/2.0/task/{offer_id}/codes`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {PartnerRedeemCodePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerRedeemCode: async (body: PartnerRedeemCodePayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling partnerControllerRedeemCode.');
      }
      const localVarPath = `/api/2.0/partner/redeem-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Размер файла не может превышать 1Mb. Поддерживаемые форматы: <b>.txt</b>
     * @summary Загрузить промо-коды
     * @param {string} file
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerUploadCoupons: async (file: string, offerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling partnerControllerUploadCoupons.');
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling partnerControllerUploadCoupons.');
      }
      const localVarPath = `/api/2.0/task/{offer_id}/codes`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
     * Генерация xhr quest data для загрузки файла с промокодом
     * @summary Xhr request data
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerCodeUploadXhrData: async (offerId: number, options: any = {}) => {
      const localVarPath = `${configuration ? configuration.basePath : ""}/api/2.0/task/{offer_id}/codes`
        .replace(`{${'offer_id'}}`, encodeURIComponent(String(offerId)));
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      function transfromHeaders(headers: { [header: string]: string }) {
        return Object.entries(headers).map(([k, v]) => { return { name: k, value: v } });
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();

      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};

      return {
        url: localVarUrlObj + localVarUrlObj.search + localVarUrlObj.hash,
        headers: transfromHeaders({ ...options.headers, ...headersFromBaseOptions }),
        method: 'POST',
        fieldName: 'file'
      }
    },
    /**
     *
     * @summary Получение баланса пользователя по номеру телефона. Авторизация по токену партнёра.
     * @param {number} rsv_user_id Идентификатор пользователя в РСВ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetBalance: async (rsv_user_id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'rsv_user_id' is not null or undefined
      if (rsv_user_id === null || rsv_user_id === undefined) {
        throw new RequiredError('rsv_user_id', 'Required parameter rsv_user_id was null or undefined when calling personControllerGetBalance.');
      }
      const localVarPath = `/api/2.0/user/{rsv_user_id}/balance`
        .replace(`{${'rsv_user_id'}}`, encodeURIComponent(String(rsv_user_id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Сгенерировать промо-коды
     * @param {number} offerId
     * @param {number} pool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGenerateCoupons(offerId: number, pool: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGenerateCoupons(offerId, pool, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updatePositionPartner(partnerPositions: PartnerPosition[]): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .updatePositionPartner(partnerPositions);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить обменный курс валют
     * @param {string} from Наименование исходной валюты
     * @param {string} to Наименование целевой валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGetExchangeRate(from: string, to: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadExchangeRateResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGetExchangeRate(from, to, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список операций (от имени организации). Авторизация по токену партнёра
     * @param {number} [rsv_user_id] Идентификатор пользователя в РСВ
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGetOperations(rsv_user_id?: number, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperationsResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGetOperations(rsv_user_id, skip, take, start, end, order, accountIds, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получение списка партнеров. Необязательная авторизация
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {number} [id] Уникальный идентификатор card&#x27;a
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGetPartners(skip?: number, take?: number, id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationCardResponseDto>>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGetPartners(skip, take, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список партнеров
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGetPartnersList(id?: number, skip?: number, take?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationsResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGetPartnersList(id, skip, take, order, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Возвращает статус загрузки промо-кодов
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerGetUploadCouponsTaskStatus(offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadCodesResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerGetUploadCouponsTaskStatus(offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {PartnerRedeemCodePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerRedeemCode(body: PartnerRedeemCodePayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedeemCodeByPartnerResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerRedeemCode(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Размер файла не может превышать 1Mb. Поддерживаемые форматы: <b>.txt</b>
     * @summary Загрузить промо-коды
     * @param {string} file
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async partnerControllerUploadCoupons(file: string, offerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCodesResponseDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerUploadCoupons(file, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async partnerControllerCodeUploadXhrData(offerId: number, options?: any) {
      const xhrRequestArgs = await PartnerApiAxiosParamCreator(configuration)
        .partnerControllerCodeUploadXhrData(offerId, options);

      return xhrRequestArgs;
    },
    /**
     *
     * @summary Получение баланса пользователя по номеру телефона. Авторизация по токену партнёра.
     * @param {number} rsv_user_id Идентификатор пользователя в РСВ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetBalance(rsv_user_id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceDto>> {
      const localVarAxiosArgs = await PartnerApiAxiosParamCreator(configuration)
        .personControllerGetBalance(rsv_user_id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Сгенерировать промо-коды
     * @param {number} offerId
     * @param {number} pool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGenerateCoupons(offerId: number, pool: number, options?: any): AxiosPromise<void> {
      return PartnerApiFp(configuration)
        .partnerControllerGenerateCoupons(offerId, pool, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить обменный курс валют
     * @param {string} from Наименование исходной валюты
     * @param {string} to Наименование целевой валюты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetExchangeRate(from: string, to: string, options?: any): AxiosPromise<ReadExchangeRateResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerGetExchangeRate(from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список операций (от имени организации). Авторизация по токену партнёра
     * @param {number} [rsv_user_id] Идентификатор пользователя в РСВ
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetOperations(rsv_user_id?: number, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any): AxiosPromise<GetOperationsResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerGetOperations(rsv_user_id, skip, take, start, end, order, accountIds, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка партнеров. Необязательная авторизация
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {number} [id] Уникальный идентификатор card&#x27;a
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetPartners(skip?: number, take?: number, id?: number, options?: any): AxiosPromise<Array<OrganizationCardResponseDto>> {
      return PartnerApiFp(configuration)
        .partnerControllerGetPartners(skip, take, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список партнеров
     * @param {number} [id] Уникальный идентификатор организации
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetPartnersList(id?: number, skip?: number, take?: number, order?: string, options?: any): AxiosPromise<GetOrganizationsResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerGetPartnersList(id, skip, take, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Возвращает статус загрузки промо-кодов
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerGetUploadCouponsTaskStatus(offerId: number, options?: any): AxiosPromise<ReadCodesResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerGetUploadCouponsTaskStatus(offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отметить промокод как использованный
     * @param {PartnerRedeemCodePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerRedeemCode(body: PartnerRedeemCodePayloadDto, options?: any): AxiosPromise<RedeemCodeByPartnerResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerRedeemCode(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Размер файла не может превышать 1Mb. Поддерживаемые форматы: <b>.txt</b>
     * @summary Загрузить промо-коды
     * @param {string} file
     * @param {number} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    partnerControllerUploadCoupons(file: string, offerId: number, options?: any): AxiosPromise<CreateCodesResponseDto> {
      return PartnerApiFp(configuration)
        .partnerControllerUploadCoupons(file, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение баланса пользователя по номеру телефона. Авторизация по токену партнёра.
     * @param {number} rsv_user_id Идентификатор пользователя в РСВ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetBalance(rsv_user_id: number, options?: any): AxiosPromise<BalanceDto> {
      return PartnerApiFp(configuration)
        .personControllerGetBalance(rsv_user_id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
  /**
   *
   * @summary Сгенерировать промо-коды
   * @param {number} offerId
   * @param {number} pool
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGenerateCoupons(offerId: number, pool: number, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGenerateCoupons(offerId, pool, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public updatePositionPartner(partnerPositions: PartnerPosition[]) {
    return PartnerApiFp(this.configuration)
      .updatePositionPartner(partnerPositions)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить обменный курс валют
   * @param {string} from Наименование исходной валюты
   * @param {string} to Наименование целевой валюты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGetExchangeRate(from: string, to: string, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGetExchangeRate(from, to, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список операций (от имени организации). Авторизация по токену партнёра
   * @param {number} [rsv_user_id] Идентификатор пользователя в РСВ
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {string} [accountIds] Номера учетных записей, перечисленные через запятую
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGetOperations(rsv_user_id?: number, skip?: number, take?: number, start?: string, end?: string, order?: string, accountIds?: string, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGetOperations(rsv_user_id, skip, take, start, end, order, accountIds, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка партнеров. Необязательная авторизация
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {number} [id] Уникальный идентификатор card&#x27;a
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGetPartners(skip?: number, take?: number, id?: number, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGetPartners(skip, take, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список партнеров
   * @param {number} [id] Уникальный идентификатор организации
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {string} [order] Порядок сортировки записей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGetPartnersList(id?: number, skip?: number, take?: number, order?: string, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGetPartnersList(id, skip, take, order, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Возвращает статус загрузки промо-кодов
   * @param {number} offerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerGetUploadCouponsTaskStatus(offerId: number, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerGetUploadCouponsTaskStatus(offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отметить промокод как использованный
   * @param {PartnerRedeemCodePayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerRedeemCode(body: PartnerRedeemCodePayloadDto, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerRedeemCode(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Размер файла не может превышать 1Mb. Поддерживаемые форматы: <b>.txt</b>
   * @summary Загрузить промо-коды
   * @param {string} file
   * @param {number} offerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public partnerControllerUploadCoupons(file: string, offerId: number, options?: any) {
    return PartnerApiFp(this.configuration)
      .partnerControllerUploadCoupons(file, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public partnerControllerCodeUploadXhrData(offerId: number, options?: any) {
    return PartnerApiFp(this.configuration).partnerControllerCodeUploadXhrData(offerId, options);
  }
  /**
   *
   * @summary Получение баланса пользователя по номеру телефона. Авторизация по токену партнёра.
   * @param {number} rsv_user_id Идентификатор пользователя в РСВ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerApi
   */
  public personControllerGetBalance(rsv_user_id: number, options?: any) {
    return PartnerApiFp(this.configuration)
      .personControllerGetBalance(rsv_user_id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
